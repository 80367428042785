import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Seleccion } from '../modelos/seleccion.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SeleccionService } from '../seleccion.service';

@Component({
  selector: 'app-pop-up-add-proceso',
  templateUrl: './pop-up-add-proceso.component.html',
  styleUrls: ['./pop-up-add-proceso.component.css']
})
export class PopUpAddProcesoComponent implements OnInit {
  faArrowLeft=faArrowLeft;
  idProceso;
  
@Input('candidato') candidato: number;
@Input('procesos') procesos: Seleccion[];
@Output() propagar = new EventEmitter<boolean>();
formularioProceso= new FormGroup({
  Proceso : new FormControl('',Validators.required)
});
  constructor(    protected seleccionService: SeleccionService) { }
onSubmit(){
  this.seleccionService.addCandidato(this.idProceso,this.candidato).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
}

  error(error) {   
    alert("Error: " + error.error.errors);

    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
    this.propagar.emit(false);
    } else {
      alert("Error: " + data.error.errors);

    }
    //this.propagar.emit(false);
  }
 cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
  ngOnInit() {
  }

}