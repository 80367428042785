import { Component, OnInit, Input } from "@angular/core";
import { Registro } from "../modelos/registro.model";
import { Seleccion } from "../modelos/seleccion.model";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF
} from "@fortawesome/free-brands-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UsersService } from "../users.service";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { environment } from '../../environments/environment';
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-perfil-director",
  templateUrl: "./perfil-director.component.html",
  styleUrls: ["./perfil-director.component.css"]
})
export class PerfilDirectorComponent implements OnInit {
  constructor(protected userService:UsersService, private notification:NotificationService) {}
faTwitter = faTwitter;
tipo;
  urlServer = environment.url;
faArrowLeft=faArrowLeft;
faLinkedinIn = faLinkedinIn;
faInstagram = faInstagram;
faFacebookF = faFacebookF;
  newAdmin = false;
  pedagogo = new Registro();
  pedagogos : Registro[] = new Array();
  formularioAdmin= new FormGroup({
  NomApe : new FormControl('',Validators.required),
  username : new FormControl('',[Validators.required,Validators.email]),
});

abrirPopUpAdmin(){
this.newAdmin = true;
}
cerrarPopUpAdmin(){
this.newAdmin = false;
}
submitAdmin(){
  this.userService.addPedagogo(this.pedagogo).subscribe(
     data => {
        this.pedagogos.push(this.pedagogo);
        this.pedagogo = new Registro();
this.newAdmin = false;
this.notification.setNotificationBS$(true);

      },
      error => {
        console.error(error);
      }
)
}
  @Input("user") usuario: Registro;
  ngOnInit() {
    this.tipo = localStorage.getItem("tipo");
     this.userService.getPedagogo().subscribe(
      data => {
        this.pedagogos = <Registro[]>data;
      },
      error => {
        console.error(error);
      }
    );
    for (var i = 0; i < this.usuario.director.length; i++) {
      //this.usuario.director[i]
      var opciones = "";
      if (this.usuario.director[i].centro.infantil) {
        opciones += "Infantil, ";
      }
      if (this.usuario.director[i].centro.primaria) {
        opciones += "Primaria, ";
      }
      if (this.usuario.director[i].centro.eSO) {
        opciones += "ESO, ";
      }
      if (this.usuario.director[i].centro.bachillerato) {
        opciones += "Bachillerato, ";
      }
      if (this.usuario.director[i].centro.ciclos) {
        opciones += "Ciclos, ";
      }
      if (this.usuario.director[i].centro.universidad) {
        opciones += "Universidad, ";
      }
      if (this.usuario.director[i].centro.fundacion) {
        opciones += "Fundación, ";
      }
      if (this.usuario.director[i].centro.asociacion) {
        opciones += "Asociación, ";
      }
      opciones = opciones.substr(0, opciones.length - 2);
      if (opciones.lastIndexOf(",") != -1) {
        opciones = this.replaceAt(opciones, opciones.lastIndexOf(","), " y");
      }
      this.usuario.director[i].centro.opciones = opciones;
      //alert(opciones);
    }
  }

  replaceAt(string, index, replace) {
    return string.substring(0, index) + replace + string.substring(index + 1);
  }
}
