import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CualidadesService } from "../cualidades.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ReglasService } from "../reglas.service";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-pop-up-regla",
  templateUrl: "./pop-up-regla.component.html",
  styleUrls: ["./pop-up-regla.component.css"]
})
export class PopUpReglaComponent implements OnInit {
  @Output() propagar = new EventEmitter<boolean>();
  @Output() reglaEvento = new EventEmitter<any>();
  @Input("editar") editar: boolean;
  @Input("regla") reglaEditar: any;
  faArrowLeft = faArrowLeft;
  titulaciones;
  cursos;
  idiomas;
  herraminetas;
  sinOpciones = true;
  opciones;
  formularioRegla;
  regla: any = { categoria: "", condicion: "", puntos: "", tipo: "", id:"", nombre:"" };
  constructor(
    protected cualidadesService: CualidadesService,
    protected reglasService: ReglasService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    if(this.editar){ this.formularioRegla = new FormGroup({
    Puntos: new FormControl("", Validators.required)
  });
  this.regla.puntos = this.reglaEditar.puntos;
    }else{
     this.formularioRegla = new FormGroup({
    Tipo: new FormControl("", Validators.required),
    Individual: new FormControl("", Validators.required),
    Puntos: new FormControl("", Validators.required)
  });
    }
    this.cualidadesService.getTitulacion().subscribe({
      next: data => {
        this.titulaciones = data;
      },
      error: error => this.error(error) //this.estado = error
    });
    this.cualidadesService.getCursos().subscribe({
      next: data => (this.cursos = data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    this.cualidadesService.getIdiomas().subscribe({
      next: data => (this.idiomas = data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    this.cualidadesService.getHerramienta().subscribe({
      next: data => (this.herraminetas = data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }

  onChange(elemento) {
    switch (elemento.value) {
      case "1":
        this.opciones = this.titulaciones;
        this.sinOpciones = false;
        break;
      case "2":
        this.opciones = this.cursos;
        this.sinOpciones = false;
        break;
      case "3":
        this.opciones = this.idiomas;
        this.sinOpciones = false;
        break;
      case "4":
        this.opciones = this.herraminetas;
        this.sinOpciones = false;
        break;
      default:
        this.sinOpciones = true;
        break;
    }
  }
  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }

  error(error) {
    alert("Error: " + error.error.errors);

    console.error(error);
  }

  onSubmit() {
    if(this.editar){
this.regla.id = this.reglaEditar.id;
this.reglasService.editarReglas(this.regla).subscribe({
      next: data => {
        this.regla.id = data.success;
        this.reglaEvento.emit(this.regla);
        this.notification.setNotificationBS$(true);

      },
      error: error => this.error(error)
    });
    }else{
    if (this.regla.categoria > 4) {
      this.regla.condicion = "";
      this.regla.tipo = 1;
      switch (this.regla.categoria) {
        case "1":
          this.regla.nombre = "Puntos por titulación";
          break;
        case "2": //curso
          this.regla.nombre = "Puntos por curso";
          break;
        case "3": //idiomas
          this.regla.nombre = "Puntos por idioma";
          break;
        case "4": //herramientas
          this.regla.nombre = "Puntos por herramienta digital";
          break;
        case "5": //experiencia
          this.regla.nombre = "Puntos por experiencia";
          break;
        case "6": //publicaciones
          this.regla.nombre = "Puntos por publicación";
          break;
        case "7": //atribuciones
          this.regla.nombre = "Puntos por atribución docente";
          break;
        case "8": //voluntariado
          this.regla.nombre = "Puntos por voluntariado";
          break;
      }
    } else {
      this.regla.tipo = 2;
      switch (this.regla.categoria) {
        case "1":
        var vector = this.titulaciones;
        for(var i = 0; i < vector.length; i++){
          if(vector[i].id ==this.regla.condicion){
          this.regla.nombre = "Puntos por tener " + vector[i].Nombre;  
          }
        }
          
          break;
        case "2": //curso
        var vector = this.cursos;
        for(var i = 0; i < vector.length; i++){
          if(vector[i].id ==this.regla.condicion){
          this.regla.nombre = "Puntos por tener " + vector[i].Nombre;  
          }
        }
          
          break;
        case "3": //idiomas
        var vector = this.idiomas;
        for(var i = 0; i < vector.length; i++){
          if(vector[i].id ==this.regla.condicion){
          this.regla.nombre = "Puntos por hablar " + vector[i].Nombre;  
          }
        }
          
          break;
        case "4": //herramientas
        var vector = this.herraminetas;
        for(var i = 0; i < vector.length; i++){
          if(vector[i].id ==this.regla.condicion){
          this.regla.nombre = "Puntos por conocer " + vector[i].Nombre;  
          }
        }
          
          break;
      }
    }
      
    this.reglasService.postRegla(this.regla).subscribe({
      next: data => {
        this.regla.id = data.success;
        this.reglaEvento.emit(this.regla);
        this.notification.setNotificationBS$(true);
      },
      error: error => this.error(error)
    });
    }
  }
}
