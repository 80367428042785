import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Cualidades } from "../app/modelos/cualidades.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})
export class CualidadesService {
  private url = environment.url;
  constructor(protected http: HttpClient) {
    if (localStorage.getItem("token") !== null) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };

  ponerToken() {
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }
  // Cursos
  addCurso(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.post<Cualidades>(
      this.url + "/api/curso",
      cualidades,
      this.httpOptions
    );
  }
  editCurso(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.put<Cualidades>(
      this.url + "/api/curso/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }
  removeCurso(id: number): Observable<Cualidades> {
    this.ponerToken();
    return this.http.delete<Cualidades>(this.url + "/api/curso/" + id, this.httpOptions);
  }
  getCursos() {
    this.ponerToken();
    return this.http.get(this.url + "/api/curso", this.httpOptions);
  }

  // Idiomas
  addIdioma(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.post<Cualidades>(
      this.url + "/api/idioma",
      cualidades,
      this.httpOptions
    );
  }
  editIdioma(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.put<Cualidades>(
      this.url + "/api/idioma/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }
  removeIdioma(id: number): Observable<Cualidades> {
    this.ponerToken();
    return this.http.delete<Cualidades>(this.url + "/api/idioma/" + id, this.httpOptions);
  }
  getIdiomas() {
    this.ponerToken();
    return this.http.get(this.url + "/api/idioma", this.httpOptions);
  }

  // Herraminetas
  addHerramienta(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.post<Cualidades>(
      this.url + "/api/herramienta",
      cualidades,
      this.httpOptions
    );
  }
  editHerramienta(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.put<Cualidades>(
      this.url + "/api/herramienta/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }
  removeHerramienta(id: number): Observable<Cualidades> {
    this.ponerToken();
    return this.http.delete<Cualidades>( this.url + "/api/herramienta/" + id, this.httpOptions);
  }
  getHerramienta() {
    this.ponerToken();
    return this.http.get(this.url + "/api/herramienta", this.httpOptions);
  }

  // Titulación
  addTitulacion(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.post<Cualidades>(
      this.url + "/api/titulacion",
      cualidades,
      this.httpOptions
    );
  }
  editTitulacion(cualidades: Cualidades): Observable<Cualidades> {
    this.ponerToken();
    return this.http.put<Cualidades>(
      this.url + "/api/titulacion/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }
  removeTitulacion(id: number): Observable<Cualidades> {
    this.ponerToken();
    return this.http.delete<Cualidades>( this.url + "/api/titulacion/" + id, this.httpOptions);
  }
  getTitulacion() {
    this.ponerToken();
    return this.http.get(this.url + "/api/titulacion", this.httpOptions);
  }
}
