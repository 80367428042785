import { Component, OnInit, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { Registro } from "../modelos/registro.model";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';
import { NotificationService } from "../notification.service";
@Component({
  selector: "app-editar-perfil",
  templateUrl: "./editar-perfil.component.html",
  styleUrls: ["./editar-perfil.component.css"]
})
export class EditarPerfilComponent implements OnInit {
  urlServer = environment.url;
  tipoUser;
  resizeImg:boolean = false;
  fileAvatar:Blob;
  usuario = new Registro();
  formularioUsuario = new FormGroup({
    nombre: new FormControl("", Validators.required),
    username: new FormControl("", [Validators.required, Validators.email]),
    tel: new FormControl("", Validators.required),
    ciudad: new FormControl("", Validators.required),
    ciudad2: new FormControl("", Validators.required),
    password: new FormControl(""),
    password2: new FormControl(""),
    edad: new FormControl("", Validators.required),
    descripcion: new FormControl(""),
    alumno: new FormControl(""),
    facebook: new FormControl(""),
    twitter: new FormControl(""),
    linkedin: new FormControl("")
  });
;

  inputImg: ElementRef;
  constructor(protected userService: UsersService, private router: Router,protected http: HttpClient, private notification:NotificationService) {}
eligirImg(){
  this.resizeImg = true;
}
cerrar(){
  this.resizeImg = false;
}
  faArrowLeft = faArrowLeft;
  faCamera = faCamera;
  onSubmit(){
    

    this.userService.editarUsuario(this.usuario).subscribe({
      next: data => {this.respuesta(data)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  error(error) {
    alert("Error: " + error.error.errors);

    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      this.router.navigate(["/perfil"]);
    } else {
      alert("Error: " + data.error.errors);
    }
    //this.propagar.emit(false);
  }
  ngOnInit() {
    if (this.userService.isLogin()) {
      this.userService.getMe().subscribe(
        data => {
          // Success
          this.usuario = <Registro>data;
          this.usuario.password = "";
          this.tipoUser = "Candidato";
          for (var i = 0; i < this.usuario.roles.length; i++) {
            if (this.usuario.roles[i] == "ROLE_ADMIN") {
              this.tipoUser = "Super Usuario";
              break;
            }
            if (this.usuario.roles[i] == "ROLE_DIRECTOR") {
              this.tipoUser = "Director";
            }
          }
        },
        error => {
          this.router.navigate([""]);
        }
      );
    } else {
      this.router.navigate([""]);
    }
  }


imageUrl;
    uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
      }
      // ChangeDetectorRef since file is loading outside the zone
    }
  }

  recogerImagen(mensaje){
    this.imageUrl = mensaje;
    this.fileAvatar = this.dataURItoBlob(mensaje);
  this.resizeImg = false;
this.userService.avatarUsuario(this.blobToFile(this.fileAvatar,"image.png")).subscribe({
      next: data => {        this.notification.setNotificationBS$(true);
      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });



  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}
dataURItoBlob(dataURI) {
  let byteString;
  if(dataURI.split(',').length >1){
   
    byteString = window.atob(dataURI.split(',')[1]);
  }else{
    byteString = window.atob(dataURI);
  }
   const arrayBuffer = new ArrayBuffer(byteString.length);
   const int8Array = new Uint8Array(arrayBuffer);
   for (let i = 0; i < byteString.length; i++) {
     int8Array[i] = byteString.charCodeAt(i);
   }
   const blob = new Blob([int8Array], { type: 'image/png' });    
   return blob;
}


}
