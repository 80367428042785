import { Component, OnInit, Input } from '@angular/core';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap'; 
import { Registro } from '../modelos/registro.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Seleccion } from '../modelos/seleccion.model';
import { SeleccionService } from '../seleccion.service';
import { faSortAmountDownAlt,faSortAmountUp, faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NotificationService } from '../notification.service';


@Component({
  selector: 'app-tabla-proceso-seleccion',
  templateUrl: './tabla-proceso-seleccion.component.html',
  styleUrls: ['./tabla-proceso-seleccion.component.css'],
  providers: [NgbPaginationConfig]
})
export class TablaProcesoSeleccionComponent implements OnInit {
  @Input("candidato") candidato:boolean;
  faEye=faEye;
  tituloConfirm = "";
mensajeConfirm = "";
  activoConfirm = false;
  apuntarConfirm = false;
  faPen= faPen;
  tipo;
  faTrash = faTrash;
faSortAmountDownAlt = faSortAmountDownAlt;
faSortAmountUp = faSortAmountUp;
activo:string;
  procesosActivo: Seleccion;
  procesosTotal: Seleccion[];
  procesosFiltro: Seleccion[];
  procesosList: Seleccion[];
  totalItems: number;
  page: number;
  previousPage: number;
  showPagination: boolean;
  pageSize: number;
  filtroActivo: string;
  orden: number;

  constructor(
    private route: ActivatedRoute,
    private seleccionService: SeleccionService,
    private config: NgbPaginationConfig,
    private router: Router,
    private notification: NotificationService
  ) {
      this.config.boundaryLinks = true;
  }

  ngOnInit() {
    this.tipo = localStorage.getItem("tipo");
this.filtroActivo = "";
this.orden =0;
	this.page =1;
	this.previousPage =1;
  this.showPagination = false;
    this.seleccionService.getProcesosListaPerfil().subscribe(
      data=>{
      this.procesosTotal = <Seleccion[]>data;
      this.filtrar('abiertos');
  this.totalItems = this.procesosFiltro.length;
  this.cambio(5);
  },
      error=>{
        console.error("ERROR")
      }
    );
    this.fillStudents(1);
  }
  getIcon(variable){
    if(this.filtroActivo == variable){
      if(this.orden == -1){
    return faSortAmountDownAlt;
      }else{
    return faSortAmountUp;
      }
    }else{
      return ;
    }
  }
  ordenar(parametro){
    if(this.filtroActivo == parametro){
      if(this.orden == 1){
        this.orden = -1;
      }else{
        this.orden = 1;
      }
    }else{
        this.orden = 1;
    }
    this.filtroActivo = parametro;
    if(this.orden == 1){
    this.procesosFiltro.sort((a,b)=> `${a[parametro]}`.localeCompare(`${b[parametro]}`));
    }else{
    this.procesosFiltro.sort((a,b)=> `${b[parametro]}`.localeCompare(`${a[parametro]}`));
    }
    this.fillStudents(this.page);
  }
  cambio(size: number){
  this.pageSize = size;
  if(this.pageSize >= this.procesosFiltro.length){
    this.showPagination = false;
  }else{
    this.showPagination = true;
    }
    this.fillStudents(this.page);
  }
  
  fillStudents(page : number) : void {
this.procesosList = this.procesosFiltro?.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
     this.fillStudents(this.page-1);
    }
  }

filtrar(tipo){
  this.activo = tipo;
  switch (tipo){
    case 'todos':
    this.procesosFiltro = this.procesosTotal;
    break;
    case 'cerrados':
    this.procesosFiltro = this.procesosTotal.filter(this.isCerrado);
    break;
    case 'abiertos':
    this.procesosFiltro = this.procesosTotal.filter(this.isActivo);
    break;
  }
  this.totalItems = this.procesosFiltro.length;
  if(this.pageSize >= this.procesosFiltro.length){
    this.showPagination = false;
  }else{
    this.showPagination = true;
    }
    this.fillStudents(this.page);
}

isActivo(x:Seleccion) {
     return x.Estado == "0";
}
isCerrado(x:Seleccion) {
     return x.Estado == "1";
}

eliminar(proceso:Seleccion){
this.procesosActivo = proceso;
this.tituloConfirm = "Eliminar proceso";
this.mensajeConfirm = "¿seguro que desea eliminar el proceso de selección? (Se eliminiará el proceso y todo lo asociado a el)";
  this.activoConfirm = true;
}

apuntar(proceso:Seleccion){
  this.procesosActivo = proceso;
  this.tituloConfirm = "Apuntarse al proceso";
  this.mensajeConfirm = "¿seguro que desea apuntarse al proceso de selección?";
    this.apuntarConfirm = true;
  }
procesaApuntarConfirmar(data){
  if (!(data instanceof Event)) {
    this.apuntarConfirm = false;
    if(data){
      this.seleccionService.addMeCandidato(this.procesosActivo.id).subscribe(
    data=>{
      this.procesosActivo.Seleccionado = true;
      for (let index = 0; index < this.procesosTotal.length; index++) {
        if(this.procesosTotal[index].id == this.procesosActivo.id){
          this.procesosTotal[index].Seleccionado = true; 
        }        
      }
      for (let index = 0; index < this.procesosFiltro.length; index++) {
        if(this.procesosFiltro[index].id == this.procesosActivo.id){
          this.procesosFiltro[index].Seleccionado = true; 
        }        
      }
      for (let index = 0; index < this.procesosList.length; index++) {
        if(this.procesosList[index].id == this.procesosActivo.id){
          this.procesosList[index].Seleccionado = true; 
        }        
      }
      this.notification.setNotificationBS$(true);

},
    error=>{
      console.error("ERROR")
    }
  );
    }
  }

}
procesaCerrarConfirmar(data){
    if (!(data instanceof Event)) {
      this.activoConfirm = false;
      if(data){
        this.seleccionService.eliminarProceso(this.procesosActivo.id).subscribe(
      data=>{
        for(var i = 0; i < this.procesosTotal.length; i++){
          if(this.procesosTotal[i].id == this.procesosActivo.id){
            this.procesosTotal.splice(i, 1);
            break;
          }
          this.notification.setNotificationBS$(true);

        }
      this.filtrar(this.activo);
  this.totalItems = this.procesosFiltro.length;
  this.cambio(5);
  },
      error=>{
        console.error("ERROR")
      }
    );
      }
    }

}
}