import { Component, OnInit,  Input, Output, EventEmitter } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent implements OnInit {
  faArrowLeft = faArrowLeft;
@Input('titulo') titulo: string;
@Input('mensaje') mensaje: string;
@Output() propagar = new EventEmitter<boolean>();

  constructor() { }
  ngOnInit() {
  }
cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
  aceptarPopUp(event: Event){
    this.propagar.emit(true);
  }
}