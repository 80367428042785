import { Component, OnInit } from "@angular/core";
import { CentroService } from "../centro.service";
import { Centro } from "../modelos/centro.model";
import { Cualidades } from "../modelos/cualidades.model";
import { CualidadesService } from "../cualidades.service";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { UsersService } from "../users.service";
import { Registro } from "../modelos/registro.model";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-perfil-super-usuario",
  templateUrl: "./perfil-super-usuario.component.html",
  styleUrls: ["./perfil-super-usuario.component.css"],
})
export class PerfilSuperUsuarioComponent implements OnInit {
  centros: Centro[];
  faArrowLeft = faArrowLeft;
  cursos: Cualidades[];
  idiomas: Cualidades[];
  herramientas: Cualidades[];
  titulacion: Cualidades[];
  objetoEditar = new Cualidades();
  tituloPopUp = "";
  tituloConfirm = "";
  mensajeConfirm = "¿Seguro que desea eliminar este elemento de su currículum?";
  activoPopUp = false;
  newAdmin = false;
  activoConfirm = false;
  editar;
  admin = new Registro();
  admins: Registro[] = new Array();
  formularioAdmin = new FormGroup({
    NomApe: new FormControl("", Validators.required),
    username: new FormControl("", [Validators.required, Validators.email]),
  });
  abrirPopUpAdmin() {
    this.newAdmin = true;
  }
  cerrarPopUpAdmin() {
    this.newAdmin = false;
  }
  submitAdmin() {
    this.userService.addAdmin(this.admin).subscribe(
      (data) => {
        this.admins.push(this.admin);
        this.admin = new Registro();
        this.newAdmin = false;
        this.notification.setNotificationBS$(true);
      },
      (error) => {
        console.error(error);
        alert("Error: " + error.error.errors);
      }
    );
  }
  abrirPopUp(tipo) {
    this.tituloPopUp = tipo;
    this.activoPopUp = true;
    this.editar = false;
  }
  procesaCerrarPopUp(mensaje) {
    this.activoPopUp = mensaje;
  }

  procesaCualidades(mensaje) {
    this.activoPopUp = false; //mensajes de creación de cosas
    switch (this.tituloPopUp) {
      case "Curso":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        this.cursos.push(newCualidad);
        break;
      case "Idioma":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        this.idiomas.push(newCualidad);
        break;
      case "Herramienta Digital":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        this.herramientas.push(newCualidad);
        break;
      case "Titulación":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        this.titulacion.push(newCualidad);
        break;
      /*case "Editar Titulación":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        var cont = 0;
        var idEncontrada;
        this.titulacion.forEach(function(value) {
          if (value.id == newCualidad.id) {
            console.log(value);
            idEncontrada = cont;
          }
          cont++;
        });
        this.titulacion[idEncontrada] = newCualidad;
        break;
      case "Editar Idioma":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        var cont = 0;
        var idEncontrada;
        this.idiomas.forEach(function(value) {
          if (value.id == newCualidad.id) {
            console.log(value);
            idEncontrada = cont;
          }
          cont++;
        });
        this.idiomas[idEncontrada] = newCualidad;
        break;
      case "Editar Curso":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        var cont = 0;
        var idEncontrada;
        this.cursos.forEach(function(value) {
          if (value.id == newCualidad.id) {
            console.log(value);
            idEncontrada = cont;
          }
          cont++;
        });
        this.cursos[idEncontrada] = newCualidad;
        break;
      case "Editar Herramienta Digital":
        var newCualidad = new Cualidades();
        newCualidad = mensaje;
        var cont = 0;
        var idEncontrada;
        this.herramientas.forEach(function(value) {
          if (value.id == newCualidad.id) {
            console.log(value);
            idEncontrada = cont;
          }
          cont++;
        });
        this.herramientas[idEncontrada] = newCualidad;
        break;*/
    }
  }
  constructor(
    protected centroService: CentroService,
    protected cualidadesService: CualidadesService,
    protected userService: UsersService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.userService.getAdmin().subscribe(
      (data) => {
        this.admins = <Registro[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
    this.centroService.getCentros().subscribe(
      (data) => {
        this.centros = <Centro[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
    this.cualidadesService.getCursos().subscribe(
      (data) => {
        this.cursos = <Cualidades[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
    this.cualidadesService.getIdiomas().subscribe(
      (data) => {
        this.idiomas = <Cualidades[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
    this.cualidadesService.getHerramienta().subscribe(
      (data) => {
        this.herramientas = <Cualidades[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
    this.cualidadesService.getTitulacion().subscribe(
      (data) => {
        this.titulacion = <Cualidades[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
  }
  activo: Cualidades;
  tipo = "";
  eliminarCurso(curso) {
    this.tipo = "Curso";
    this.tituloConfirm = "Eliminar " + this.tipo;
    this.activoConfirm = true;
    this.activo = curso;
    /*this.cualidadesService.removeCurso(curso.id).subscribe({
      next: data => this.eliminarArray(curso.id, this.cursos), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });*/
  }
  eliminarIdioma(idioma) {
    this.tipo = "Idioma";
    this.tituloConfirm = "Eliminar " + this.tipo;
    this.activoConfirm = true;
    this.activo = idioma; /*
    this.cualidadesService.removeIdioma(idioma.id).subscribe({
      next: data => this.eliminarArray(idioma.id, this.idiomas), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });*/
  }
  eliminarTitulacion(titulacion) {
    this.tipo = "Titulación";
    this.tituloConfirm = "Eliminar " + this.tipo;
    this.activoConfirm = true;
    this.activo = titulacion; /*
    this.cualidadesService.removeTitulacion(titulacion.id).subscribe({
      next: data => this.eliminarArray(titulacion.id, this.titulacion), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });*/
  }
  eliminarHerramienta(herramienta) {
    this.tipo = "Herramienta";
    this.tituloConfirm = "Eliminar " + this.tipo;
    this.activoConfirm = true;
    this.activo = herramienta; /*
    this.cualidadesService.removeHerramienta(herramienta.id).subscribe({
      next: data => this.eliminarArray(herramienta.id, this.herramientas), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });*/
  }
  editarIdioma(Idioma) {
    this.tipo = "Idioma";
    this.tituloPopUp = "Editar " + this.tipo;
    this.activoPopUp = true;
    this.editar = true;
    this.objetoEditar = Idioma;
  }
  editarHerramienta(Herramienta) {
    this.tipo = "Herramienta Digital";
    this.tituloPopUp = "Editar " + this.tipo;
    this.activoPopUp = true;
    this.editar = true;
    this.objetoEditar = Herramienta;
  }
  editarCurso(Curso) {
    this.tipo = "Curso";
    this.tituloPopUp = "Editar " + this.tipo;
    this.activoPopUp = true;
    this.editar = true;
    this.objetoEditar = Curso;
  }
  editarTitulacion(Titulacion) {
    this.tipo = "Titulación";
    this.tituloPopUp = "Editar " + this.tipo;
    this.activoPopUp = true;
    this.editar = true;
    this.objetoEditar = Titulacion;
  }

  procesaCerrarConfirmar(mensaje) {
    this.activoConfirm = false;
    if (mensaje) {
      switch (
        this.tipo //llamar a cada uno para eliminar
      ) {
        case "Herramienta":
          this.cualidadesService.removeHerramienta(this.activo.id).subscribe({
            next: (data) => {
              this.eliminarArray(this.activo.id, this.herramientas);
              this.notification.setNotificationBS$(true);
            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
          break;
        case "Titulación":
          this.cualidadesService.removeTitulacion(this.activo.id).subscribe({
            next: (data) => this.eliminarArray(this.activo.id, this.titulacion), //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
          break;
        case "Idioma":
          this.cualidadesService.removeIdioma(this.activo.id).subscribe({
            next: (data) => {
              this.eliminarArray(this.activo.id, this.idiomas);
              this.notification.setNotificationBS$(true);
            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
          break;
        case "Curso":
          this.cualidadesService.removeCurso(this.activo.id).subscribe({
            next: (data) => {
              this.eliminarArray(this.activo.id, this.cursos);
              this.notification.setNotificationBS$(true);
            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
          break;
      }
    } else {
      this.tipo = "";
      this.activo = null;
    }
  }
  error(error) {
    alert("Error: " + error.error.errors);

    console.error(error);
  }
  eliminarArray(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        array.splice(i, 1);
        break;
      }
    }
  }
}
