import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CheckForUpdateService } from './check-for-update.service';
import { NotificationService } from './notification.service';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent  {
  updateAvailable = false;
  showNotification = false;
  
  constructor(private updates: SwUpdate,
    private checkForUpdateService: CheckForUpdateService,
    private notification:NotificationService
  ) {
      this.updates.available.subscribe((event) => {
        this.updateAvailable = true;
      });
  }

  ngOnInit(): void {
    this.notification.getNotificationBS$().subscribe((value)=>{
      this.showNotification = value;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    })
  }
  
}
