import { Component, OnInit, Input } from "@angular/core";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { Atributos } from "../modelos/atributos.model";
import { CualidadesUsuario } from "../modelos/cualidades_usuario.model";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";
import { Experiencia } from "../modelos/experiencia.model";
import { ExperienciaService } from "../experiencia.service";
import { Registro } from "../modelos/registro.model";
import { Voluntariado } from "../modelos/voluntariado.model";
import { Atribucion } from "../modelos/atribucion.model";
import { Publicacion } from "../modelos/publicacion.model";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-perfil-candidato",
  templateUrl: "./perfil-candidato.component.html",
  styleUrls: ["./perfil-candidato.component.css"]
})
export class PerfilCandidatoComponent implements OnInit {
  faTrash = faTrash;
  faPen = faPen;
editar;
  @Input("user") usuario: Registro;
  propietario = false;
  objetoEditar = new Atributos();
  objetoEditar2 = new CualidadesUsuario();
  tipoUser = "";
  superUser = false;
  directorUser = false;
  normalUser = false;
  tituloPopUp = "";
  tituloConfirm = "";
  mensajeConfirm = "¿Seguro que desea eliminar este elemento de su currículum?";
  activoPopUp = false;
  activoPopUp2 = false;
  activoConfirm = false;
  constructor(
    protected userService: UsersService,
    private router: Router,
    protected experienceService: ExperienciaService,
    private notification: NotificationService
  ) {}

ngOnChanges(usuario: Registro) { 
  if(this.usuario.id != JSON.parse(localStorage.getItem("user")).id){
    this.propietario = false;
  }else{
    this.propietario = true;
  }
  
}

  ngOnInit() {
   
  }
  abrirPopUp2(tipo) {
    this.tituloPopUp = tipo;
    this.activoPopUp2 = true;
    this.editar = false;
  }
  abrirPopUp(tipo) {
    this.tituloPopUp = tipo;
    this.activoPopUp = true;
    this.editar = false;
  }
  procesaCerrarPopUp(mensaje) {
    this.activoPopUp = mensaje;
  }
  procesaCerrarPopUp2(mensaje) {
    this.activoPopUp2 = mensaje;
  }

  procesaCerrarConfirmar(mensaje) {
    this.activoConfirm = false;
    if (mensaje) {
      switch (this.tipoActivo) {
        case "Experiencia":
          this.eliminarExperiencia(this.idActivo);
          break;
        case "Voluntariado":
          this.eliminarVoluntariado(this.idActivo);
          break;
        case "Habilitación":
          this.eliminarAtribucion(this.idActivo);
          break;
        case "Publicación":
          this.eliminarPublicacion(this.idActivo);
          break;
        case "Formación":
          this.eliminarFormacion(this.idActivo);
          break;       
        case "Idioma":
          this.eliminarIdioma(this.idActivo);
          break;       
        case "Curso":
          this.eliminarCurso(this.idActivo);
          break;       
        case "Herramienta":
          this.eliminarHerramienta(this.idActivo);
          break;          
      }
    } else {
      this.tipoActivo = "";
      this.idActivo = "";
    }
  }
  procesaCualidades(mensaje) {
    this.activoPopUp2 = false;
    var obj: any = {};
    obj = mensaje;
    switch (this.tituloPopUp) {
      case "Formación":
        obj.formacion = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        this.usuario.formacion.push(obj);
        break;    
      case "Cursos":
        obj.curso = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        this.usuario.cursos.push(obj);
        break;    
      case "Idiomas":
        obj.idioma = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        this.usuario.idiomas.push(obj);
        break;    
      case "Herramientas digitales":
        obj.herramienta = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        this.usuario.herraminetas.push(obj);
        break;        
      case "Editar Formación":
      //alert();
        obj.formacion = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        var cont = 0;
        var idEncontrada;
        this.usuario.formacion.forEach(function(value) {
          if (value.id == obj.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.formacion[idEncontrada] = obj;
        break;      
      case "Editar Idioma":
      //alert();
        obj.idioma = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        var cont = 0;
        var idEncontrada;
        this.usuario.idiomas.forEach(function(value) {
          if (value.id == obj.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.idiomas[idEncontrada] = obj;
        break;      
      case "Editar Curso":
      //alert();
        obj.curso = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        var cont = 0;
        var idEncontrada;
        this.usuario.cursos.forEach(function(value) {
          if (value.id == obj.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.cursos[idEncontrada] = obj;
        break;      
      case "Editar Herramienta":
      //alert();
        obj.herramienta = { nombre: mensaje.Cualidad, id: mensaje.idCualidad };
        var cont = 0;
        var idEncontrada;
        this.usuario.herraminetas.forEach(function(value) {
          if (value.id == obj.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.herraminetas[idEncontrada] = obj;
        break;

    }
  }
  procesaAtributo(mensaje) {
    this.activoPopUp = false;
    switch (this.tituloPopUp) {
      case "Experiencia laboral":
        var newExperiencia = new Experiencia();
        newExperiencia.cargar(mensaje);
        this.usuario.experiencias.push(newExperiencia);
        break;
      case "Voluntariado":
        var newVoluntariado = new Voluntariado();
        newVoluntariado.cargar(mensaje);
        this.usuario.voluntariados.push(newVoluntariado);
        break;
      case "Habilitaciones":
        var newAtribucion = new Atribucion();
        newAtribucion.cargar(mensaje);
        this.usuario.atribuciones.push(newAtribucion);
        break;
      case "Publicaciones":
        var newPublicacion = new Publicacion();
        newPublicacion.cargar(mensaje);
        this.usuario.publicaciones.push(newPublicacion);
        break;

      case "Editar Habilitación":
        var newAtribucion = new Atribucion();
        newAtribucion.cargar(mensaje);
        var cont = 0;
        var idEncontrada;
        this.usuario.atribuciones.forEach(function(value) {
          if (value.id == newAtribucion.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.atribuciones[idEncontrada] = newAtribucion;
        break;

      case "Editar Experiencia":
        var newExperiencia = new Experiencia();
        newExperiencia.cargar(mensaje);
        var cont = 0;
        var idEncontrada;
        this.usuario.experiencias.forEach(function(value) {
          if (value.id == newExperiencia.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.experiencias[idEncontrada] = newExperiencia;
        break;
      case "Editar Voluntariado":
        var newVoluntariado = new Voluntariado();
        newVoluntariado.cargar(mensaje);
        var cont = 0;
        var idEncontrada;
        this.usuario.voluntariados.forEach(function(value) {
          if (value.id == newVoluntariado.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.voluntariados[idEncontrada] = newVoluntariado;
        break;
      case "Editar Publicación":
        var newPublicacion = new Publicacion();
        newPublicacion.cargar(mensaje);
        var cont = 0;
        var idEncontrada;
        this.usuario.publicaciones.forEach(function(value) {
          if (value.id == newPublicacion.id) {
            idEncontrada = cont;
          }
          cont++;
        });
        this.usuario.publicaciones[idEncontrada] = newPublicacion;
        break;
    }
  }
  /*eliminar*/
  eliminarArray(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        array.splice(i, 1);
        break;
      }
    }
  }
  tipoActivo;
  idActivo;

  
  confirmacionPopUp2(id, tipo, editar) {
    if (editar) {
      this.editar = true;
      this.tituloPopUp = "Editar " + tipo;
      this.activoPopUp2 = true;
      this.objetoEditar2.id = id;
      var cont = 0;
      var idEncontrada;
      var busqueda;
      switch (tipo) {
        case "Formación":
          busqueda = this.usuario.formacion;
          break;
        case "Curso":
          busqueda = this.usuario.cursos;
          break;
        case "Herramienta":
          busqueda = this.usuario.herraminetas;
          break;
        case "Idioma":
          busqueda = this.usuario.idiomas;
          break;
      }
      busqueda.forEach(function(value) {
        if (value.id == id) {
          idEncontrada = cont;
        }
        cont++;
      });

        this.objetoEditar2.Inicio = busqueda[idEncontrada].Inicio;
        this.objetoEditar2.Final = busqueda[idEncontrada].Final;
        this.objetoEditar2.Lugar = busqueda[idEncontrada].Lugar;
        this.objetoEditar2.Nivel = busqueda[idEncontrada].Nivel;
        switch (tipo) {
        case "Formación":
        this.objetoEditar2.idCualidad = busqueda[idEncontrada].formacion.id;
        break;
        case "Idioma":
        this.objetoEditar2.idCualidad = busqueda[idEncontrada].idioma.id;
        break;
        case "Herramienta":
        this.objetoEditar2.idCualidad = busqueda[idEncontrada].herramienta.id;
        break;
        case "Curso":
        this.objetoEditar2.idCualidad = busqueda[idEncontrada].curso.id;
        break;
        }
      
    } else {
      this.tituloConfirm = "Eliminar " + tipo;
      this.activoConfirm = true;
      this.tipoActivo = tipo;
      this.idActivo = id;
    }
  }

  openConfirmationPopup(id, tipo) {
    this.tituloConfirm = "Eliminar " + tipo;
    this.activoConfirm = true;
  }


  confirmacionPopUp(id, tipo, editar) {
    if (editar) {
      this.editar = true;
      this.tituloPopUp = "Editar " + tipo;
      this.activoPopUp = true;
      this.objetoEditar.id = id;
      var cont = 0;
      var idEncontrada;
      var busqueda;
      switch (tipo) {
        case "Experiencia":
          busqueda = this.usuario.experiencias;
          break;
        case "Voluntariado":
          busqueda = this.usuario.voluntariados;
          break;
        case "Habilitación":
          busqueda = this.usuario.atribuciones;
          break;
        case "Publicación":
          busqueda = this.usuario.publicaciones;
          break;
      }
      busqueda.forEach(function(value) {
        if (value.id == id) {
          idEncontrada = cont;
        }
        cont++;
      });

      if (tipo == "Publicación") {
        this.objetoEditar.titulo = busqueda[idEncontrada].Titulo;
        this.objetoEditar.autor = busqueda[idEncontrada].Autor;

        this.objetoEditar.fecha =
          busqueda[idEncontrada].Fecha.split("-")[2] +
          "-" +
          busqueda[idEncontrada].Fecha.split("-")[1] +
          "-" +
          busqueda[idEncontrada].Fecha.split("-")[0];
        this.objetoEditar.url = busqueda[idEncontrada].Url;
        this.objetoEditar.revista = busqueda[idEncontrada].Revista;
        this.objetoEditar.volumen = busqueda[idEncontrada].Volumen;
        this.objetoEditar.descripcion = busqueda[idEncontrada].Medio;
      } else {
        this.objetoEditar.inicio = busqueda[idEncontrada].Inicio;
        if (tipo == "Habilitación") {
          this.objetoEditar.puesto = busqueda[idEncontrada].Nombre;
        } else {
          this.objetoEditar.puesto = busqueda[idEncontrada].Puesto;
        }
        this.objetoEditar.descripcion = busqueda[idEncontrada].Descripcion;
        this.objetoEditar.fin = busqueda[idEncontrada].Final;
        this.objetoEditar.centro = busqueda[idEncontrada].Centro;
      }
    } else {
      this.tituloConfirm = "Eliminar " + tipo;
      this.activoConfirm = true;
      this.tipoActivo = tipo;
      this.idActivo = id;
    }
  }
  eliminarExperiencia(id) {
    this.experienceService.removeExperiencia(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.experiencias)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarVoluntariado(id) {
    this.experienceService.removeVoluntariado(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.voluntariados)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarAtribucion(id) {
    this.experienceService.removeAtribucion(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.atribuciones)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarPublicacion(id) {
    this.experienceService.removePublicacion(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.publicaciones)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarFormacion(id) {
    this.experienceService.removeFormacioUsuario(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.formacion)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarCurso(id) {
    this.experienceService.removeCursoUsuario(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.cursos)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarIdioma(id) {
    this.experienceService.removeIdiomaUsuario(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.idiomas)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  eliminarHerramienta(id) {
    this.experienceService.removeHerramientaUsuario(id).subscribe({
      next: data => {this.correcto(data, id, this.usuario.herraminetas)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }

  
  correcto(data, id, array) {
    if (data.status == 200) {
      this.eliminarArray(id, array);
    } else {
      alert("Error: " + data.error.errors);

    }
  }

  error(error) {
    alert("Error: " + error.error.errors);

    console.error(error);
  }
}
