import { Injectable } from '@angular/core';
import { Comentario } from "../app/modelos/comentario.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})
export class ComentariosService {
  private url = environment.url;
  constructor(protected http: HttpClient) {
    if (localStorage.getItem("token") !== null) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };
  ponerToken(){
    this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
  }

  
  addComentario(comentario: Comentario): Observable<Comentario> {
    this.ponerToken();
  return this.http.post<Comentario>(this.url+'/api/comentario', comentario, this.httpOptions);
}
  getComentarios(idUser: number) {
    this.ponerToken();
    return this.http.get(this.url + "/api/comentario/"+idUser, this.httpOptions);
}
 removeComentario(idComentario: number): Observable<Comentario> {
    this.ponerToken();
  return this.http.delete<Comentario>(this.url+'/api/comentario/'+idComentario, this.httpOptions);
}

}