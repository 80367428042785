import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css']
})
export class ToasterComponent implements OnInit { 
  @Input() show = false;

  ngOnInit() {
  }
  reload() {
    document.location.reload();
  }

}
