import { Registro } from './registro.model';
import { Seleccion } from './seleccion.model';

export class Reunion {
  public Fecha: string;
  public Lugar: string;
  public Hora: string;
  public Proceso: Seleccion;
  public Estado: string;
  public Candidato: Registro
  public id: number;
  constructor() {}
}