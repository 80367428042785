export class Voluntariado {
  public Centro: string;
  public Inicio: string;
  public Final: string;
  public Descripcion: string;
  public id: number;
  constructor() {}
  cargar(data) {
    this.Centro = data.centro;
    this.Inicio = data.inicio;
    this.Final = data.fin;
    this.Descripcion = data.descripcion;
    this.id = Number(data.id);
  }
}
