import { Centro } from './centro.model';
import { Registro } from './registro.model';

export class Seleccion {
  public Nivel: string;
  public Titulo: string;
  public Descripcion: string;
  public Creacion: string;
  public Inicio: string;
  public Fin: string;
  public Horas: string;
  public Tipo: string;
  public centro: Centro;
  public Estado: string;
  public candidatos: Registro[];
  public id: number;
  public Seleccionado: boolean;
  constructor() {}
}