export class Comentario {
    public Comentario: string;
    public id: number;
    public fecha:string;
    public Fecha:string;
    public candidato:string;
    public director:string;
  public success: number;
  constructor() {  }

}