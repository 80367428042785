import { Component, OnInit } from '@angular/core';
import {UsersService } from '../users.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  users: any = [];
  constructor(
    protected userService: UsersService
  ) {
  }
  ngOnInit() {
  }
}