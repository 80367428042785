import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { Reunion } from "../app/modelos/reunion.model";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})
export class ReglasService {
  private url = environment.url;
  constructor(protected http: HttpClient) {
    if (localStorage.getItem("token") !== null) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };
  ponerToken() {
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }

  verReglas(): Observable<any> {
    this.ponerToken();
    return this.http.get(
      this.url + "/api/regla",
      this.httpOptions
    );
  }
  eliminarReglas(id): Observable<any> {
    this.ponerToken();
    return this.http.delete(
      this.url + "/api/regla/"+id,
      this.httpOptions
    );
  }
  editarReglas(regla:any): Observable<any> {
    this.ponerToken();
    return this.http.put(
      this.url + "/api/regla/"+regla.id,regla,
      this.httpOptions
    );
  }
  postRegla(regla:any): Observable<any> {
    this.ponerToken();
    return this.http.post(
      this.url + "/api/regla" ,regla,
      this.httpOptions
    );
  }
}
