import { Director } from './director.model';
export class Centro {
  public Nombre: string;
  public Direccion: string;
  public Telefono: string;
  public Email: string;
  public Comunidad: string;
  public Web: string;
  public Facebook: string;
  public Instagram: string;
  public Twitter: string;
  public Linkedin: string;
  public Infantil: boolean;
  public Primaria: boolean;
  public ESO: boolean;
  public Bachillerato: boolean;
  public Ciclos: boolean;
  public Universidad: boolean;
  public Fundacion: boolean;
  public Asociacion: boolean;
  public Seleccionado: boolean;
  public id: number;
  public activo: boolean;
  public directores: Director[];
  public success: any;
  constructor() {}
}
