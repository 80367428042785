import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Registro } from "../app/modelos/registro.model";
import { Validar } from "../app/modelos/validar.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})
export class UsersService { 
  private url = environment.url;
  constructor(protected http: HttpClient) {
    if (this.isLogin()) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
cabeceraFiles(){
  var httpOptions2 = {
    headers: new HttpHeaders({
    })
  };
  httpOptions2.headers = httpOptions2.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

  return httpOptions2;
}
   private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };
  ponerToken(){
    this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
  }

  addToken(token) {
    localStorage.setItem("token", token);
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("tipo");
    localStorage.removeItem("user");
  }
  isLogin() {
    return localStorage.getItem("token") !== null;
  }
  getMe() {
    return this.http.get(this.url + "/api/user", this.httpOptions);
  }
  getNotificaciones() {
    return this.http.get(this.url + "/api/notificaciones", this.httpOptions);
  }/*
  getUsers() {
    return this.http.get(this.url + "/api/users/find", this.httpOptions);
  }*/

  getUser(id:number) {
    return this.http.get(this.url + "/api/user/"+id, this.httpOptions);
  }

  findUsers(jsonBusqueda:string){
    return this.http.get(this.url + "/api/users/find?q="+jsonBusqueda, this.httpOptions);
  }

  addUsuario(usuario: Registro): Observable<Registro> {
    return this.http.post<Registro>(this.url + "/register", usuario);
  }
  
  lostUsuario(usuario: any): Observable<any> {
    return this.http.post<any>(this.url + "/recuperarCuenta", usuario);
  }

  

addAdmin(usuario: Registro): Observable<Registro> {
    return this.http.post<Registro>(this.url + "/api/userAdmin", usuario, this.httpOptions);
  }

addPedagogo(usuario: Registro): Observable<Registro> {
    return this.http.post<Registro>(this.url + "/api/userPedagogo", usuario, this.httpOptions);
  }

  getPedagogo(): Observable<Registro[]> {
    return this.http.get<Registro[]>(this.url + "/api/pedagogos", this.httpOptions);
  }
  

getAdmin(): Observable<Registro[]> {
    return this.http.get<Registro[]>(this.url + "/api/admins", this.httpOptions);
  }
  
  autenticarUsuario(usuario: Registro): Observable<Registro> {
    return this.http.post<Registro>(this.url + "/api/login_check", usuario);
  }
   validarUsuario(validar: Validar): Observable<Validar> {
    return this.http.post<Validar>(this.url + "/test/validar", validar);
  }
  
   editarUsuario(user: Registro): Observable<Registro> {
    return this.http.put<Registro>(this.url + "/api/user", user, this.httpOptions);
  }
   estadoUsuario(): Observable<Registro> {
    return this.http.post<Registro>(this.url + "/api/estado", '', this.httpOptions);
  }

     avatarUsuario(fileAvatar:File): Observable<Registro> {
       let formData:FormData = new FormData();
  formData.append('image', fileAvatar,fileAvatar.name);
        return this.http.post<any>(this.url +'/api/image', formData, this.cabeceraFiles());
  }
  avatarCentro(fileAvatar:File,id): Observable<Registro> {
       let formData:FormData = new FormData();
  formData.append('image', fileAvatar,fileAvatar.name);
        return this.http.post<any>(this.url +'/api/imageCenter/'+id, formData, this.cabeceraFiles());
  }
  removeMe(): Observable<any> {
    this.ponerToken();
    return this.http.delete<any>(this.url + '/api/me', this.httpOptions);
  }
}