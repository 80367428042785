import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { Cualidades } from "../modelos/cualidades.model";

@Component({
  selector: 'app-listado-cualidades',
  templateUrl: './listado-cualidades.component.html',
  styleUrls: ['./listado-cualidades.component.css']
})
export class ListadoCualidadesComponent implements OnInit {
  faTrash = faTrash;
  faPen = faPen;
@Output() eliminarEvent = new EventEmitter<Cualidades>();
@Output() editEvent = new EventEmitter<Cualidades>();
@Input('datos') datos: Cualidades;
@Input('tipo') tipo: string;

  constructor() { }

  ngOnInit() {
  }
  eliminar(cualidad: Cualidades){
    this.eliminarEvent.emit(cualidad);
  }
  editar(cualidad: Cualidades){
    this.editEvent.emit(cualidad);
  }

}