import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { Centro } from "../modelos/centro.model";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF
} from "@fortawesome/free-brands-svg-icons";
import { environment } from '../../environments/environment';
@Component({
  selector: "app-linea-centro",
  templateUrl: "./linea-centro.component.html",
  styleUrls: ["./linea-centro.component.css"]
})
export class LineaCentroComponent implements OnInit {
  faUserFriends = faUserFriends;
  @Input("centro") centro: Centro;
faTwitter = faTwitter;
faLinkedinIn = faLinkedinIn;
faInstagram = faInstagram;
faFacebookF = faFacebookF;
  urlServer = environment.url;
@Output() click = new EventEmitter<any>();
  vista: boolean;
  constructor(){}
  opciones = "";
  ngOnInit() {
   
    if (this.centro.Seleccionado == undefined) {
      this.vista = true;
    } else {
      this.vista = false;
    }

    if (this.centro.Infantil) {
      this.opciones += "Infantil, ";
    }
    if (this.centro.Primaria) {
      this.opciones += "Primaria, ";
    }
    if (this.centro.ESO) {
      this.opciones += "ESO, ";
    }
    if (this.centro.Bachillerato) {
      this.opciones += "Bachillerato, ";
    }
    if (this.centro.Ciclos) {
      this.opciones += "Ciclos, ";
    }
    if (this.centro.Universidad) {
      this.opciones += "Universidad, ";
    }
    if (this.centro.Fundacion) {
      this.opciones += "Fundación, ";
    }
    if (this.centro.Asociacion) {
      this.opciones += "Asociación, ";
    }
    this.opciones = this.opciones.substr(0, this.opciones.length - 2);
    if (this.opciones.lastIndexOf(",") != -1) {
      this.opciones = this.replaceAt(
        this.opciones,
        this.opciones.lastIndexOf(","),
        " y"
      );
    }
  }
  replaceAt(string, index, replace) {
    return string.substring(0, index) + replace + string.substring(index + 1);
  }
  centroClick(event: Event){
    var respuesta = {};
    if (this.centro.Seleccionado) {
      respuesta["accion"] = false;
      }else{
      respuesta["accion"] = true;
      }
      respuesta["centro"] = this.centro;
    this.click.emit(respuesta);
  }
}
