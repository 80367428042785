import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Cualidades } from "../modelos/cualidades.model";
import { CualidadesService } from "../cualidades.service";

import { Observable } from "rxjs";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-pop-up-cualidades",
  templateUrl: "./pop-up-cualidades.component.html",
  styleUrls: ["./pop-up-cualidades.component.css"],
})
export class PopUpCualidadesComponent implements OnInit {
  formularioCualidades;
  titulacion: boolean;
  @Input("titulo") titulo: string;
  @Input("editar") editar: boolean;
  @Input("objeto") cualidadesParametros: Cualidades;
  @Output() propagar = new EventEmitter<boolean>();
  @Output() cualidades = new EventEmitter<Cualidades>();
  //atributoParametros = new Atributos();

  onSubmit() {
    switch (this.titulo) {
      case "Curso":
        this.cualidadesService.addCurso(this.cualidadesParametros).subscribe({
          next: (data) => {
            this.respuesta(data);
            this.notification.setNotificationBS$(true);
          }, //this.estado = data.status,
          error: (error) => this.error(error), //this.estado = error
        });
        break;
      case "Idioma":
        this.cualidadesService.addIdioma(this.cualidadesParametros).subscribe({
          next: (data) => {
            this.respuesta(data);
            this.notification.setNotificationBS$(true);
          }, //this.estado = data.status,
          error: (error) => this.error(error), //this.estado = error
        });
        break;
      case "Herramienta Digital":
        this.cualidadesService
          .addHerramienta(this.cualidadesParametros)
          .subscribe({
            next: (data) => {
              this.respuesta(data), this.notification.setNotificationBS$(true);
            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
        break;
      case "Titulación":
        this.cualidadesService
          .addTitulacion(this.cualidadesParametros)
          .subscribe({
            next: (data) => {this.respuesta(data)
              this.notification.setNotificationBS$(true);

            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
        break;

      case "Editar Curso":
        this.cualidadesService.editCurso(this.cualidadesParametros).subscribe({
          next: (data) => {this.respuesta(data)
            this.notification.setNotificationBS$(true);

          }, //this.estado = data.status,
          error: (error) => this.error(error), //this.estado = error
        });
        break;

      case "Editar Idioma":
        this.cualidadesService.editIdioma(this.cualidadesParametros).subscribe({
          next: (data) => {this.respuesta(data)
            this.notification.setNotificationBS$(true);

          }, //this.estado = data.status,
          error: (error) => this.error(error), //this.estado = error
        });
        break;

      case "Editar Titulación":
        this.cualidadesService
          .editTitulacion(this.cualidadesParametros)
          .subscribe({
            next: (data) => {this.respuesta(data)
              this.notification.setNotificationBS$(true);

            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
        break;

      case "Editar Herramienta Digital":
        this.cualidadesService
          .editHerramienta(this.cualidadesParametros)
          .subscribe({
            next: (data) => {this.respuesta(data)
              this.notification.setNotificationBS$(true);

            }, //this.estado = data.status,
            error: (error) => this.error(error), //this.estado = error
          });
        break;
    }
  }

  error(error) {
    alert("Error: " + error.error.errors);

    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      this.cualidadesParametros.id = data.success;
      this.cualidades.emit(this.cualidadesParametros);
    } else {
      alert("Error: " + data.error.errors);
    }
    //this.propagar.emit(false);
  }

  faArrowLeft = faArrowLeft;
  constructor(
    protected cualidadesService: CualidadesService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    //console.log(this.objeto);
    if (!this.editar) {
      this.cualidadesParametros = new Cualidades();
    }
    if (this.titulo == "Titulación" || this.titulo == "Editar Titulación") {
      this.titulacion = true;
      this.formularioCualidades = new FormGroup({
        Nombre: new FormControl("", Validators.required),
        Nivel: new FormControl("", Validators.required),
      });
    } else {
      this.titulacion = false;
      this.formularioCualidades = new FormGroup({
        Nombre: new FormControl("", Validators.required),
      });
    }
    // this.activo = false;
  }
  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
}
