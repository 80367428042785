

export class Publicacion {
  public Medio: string;
  public Autor: string;
  public Fecha: string;
  public Titulo: string;

   public Revista:string;
    public Url:string;
    public Volumen:string;
  public id: number;
  constructor() {}
  cargar(data) {
    this.Medio = data.descripcion;
    this.Autor = data.autor;
    this.Fecha = data.fecha;
    this.Titulo = data.titulo;
    this.Revista = data.revista
    this.Url = data.url
    this.Volumen = data.volumen
    this.id = Number(data.id);
  }
}
