import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Registro } from './modelos/registro.model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoManagerService {

  private _userBS$:BehaviorSubject<Registro | null> = new BehaviorSubject<Registro | null>(null)
  
constructor() { }

  public initUserInfoManagerService(){
    const localStorageInfoUser = localStorage.getItem('user');
    if(localStorageInfoUser){
      this._userBS$.next(JSON.parse(localStorageInfoUser))
    }
  }

  public getInfoUser(){
    return this._userBS$;
  }

  public setInfoUser(info:Registro){
    this._userBS$.next(info);
    localStorage.setItem('user', JSON.stringify(info));
  }

}
