import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Registro } from "../modelos/registro.model";
import { SeleccionService } from "../seleccion.service";
import { Seleccion } from "../modelos/seleccion.model";
import { Reunion } from "../modelos/reunion.model";
import { ReunionService } from "../reunion.service";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-proceso",
  templateUrl: "./proceso.component.html",
  styleUrls: ["./proceso.component.css"],
})
export class ProcesoComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected seleccionService: SeleccionService,
    protected reunionService: ReunionService,
    private notification: NotificationService
  ) {}
  usuario: Registro;
  tipo: number;
  activoConfirm: boolean = false;
  activoReunion: boolean = false;
  tituloConfirm: string = "";
  reuniones: Reunion[];
  candidato: number;
  accion: number;
  mensajeConfirm: string = "";
  procesos: Seleccion[];
  activo: Seleccion;
  candidatoSel: number;
  procesosList: any;
  candidatos: any;
  totalCandidatos: number;
  paginationFilters: { nPage: number; nReturns: number } = {
    nPage: 1,
    nReturns: 5,
  };
  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.tipo = +localStorage.getItem("tipo");
    if (this.tipo == 1) {
      this.route.paramMap.subscribe((params) => {
        let id_buscar: number = +params.get("id");
        this.seleccionService.getProceso(id_buscar).subscribe(
          (data) => {
            this.activo = <Seleccion>data;
          },
          (error) => {
            console.error(error);
          }
        );
      });
    } else {
      this.seleccionService.getProcesosLista().subscribe(
        (data) => {
          this.procesosList = data;
        },
        (error) => {
          alert(error.error.errors);
        }
      );

      this.route.paramMap.subscribe((params) => {
        let id_buscar: number = +params.get("id");
        this.seleccionService.getProceso(id_buscar).subscribe(
          (data) => {
            this.activo = <Seleccion>data;
            this.getCandidatosByProceso(this.activo.id);
          },
          (error) => {
            console.error(error);
          }
        );
      });
    }
  }

  onProceso(id) {
    if (id != -1) {
      let id_buscar = id;
      this.seleccionService.getProceso(id_buscar).subscribe(
        (data) => {
          this.activo = <Seleccion>data;
          this.getCandidatosByProceso(this.activo.id);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.activo = undefined;
    }
  }

  contratar(data) {
    if (!(data instanceof Event)) {
      this.accion = 1;
      this.candidato = data;
      this.tituloConfirm = "Contratar candidato";
      this.mensajeConfirm =
        "¿Seguro que desea contratar a este candidato? (El resto de candidatos se descartarán automáticamente y se cerrará el proceso de selección)";
      this.activoConfirm = true;
      this.notification.setNotificationBS$(true);
    }
  }
  eliminar(data) {
    if (!(data instanceof Event)) {
      this.accion = 0;
      this.candidato = data;
      this.tituloConfirm = "Descartar candidato";
      this.mensajeConfirm =
        "¿Seguro que desea descartar al candidato de este proceso de selección?";
      this.activoConfirm = true;
    }
  }
  pedirReunion(data) {
    if (!(data instanceof Event)) {
      this.activoReunion = true;
      this.candidatoSel = data;
    }
  }
  procesaCerrarReunion(data) {
    if (!(data instanceof Event)) {
      this.activoReunion = false;
      if (data) {
        this.reunionService.getReuniones(this.activo.id).subscribe(
          (reuniones: any) => {
            for (var j = 0; j < this.candidatos.length; j++) {
              this.candidatos[j].reunion = [];
            }
            for (var i = 0; i < reuniones.length; i++) {
              for (var j = 0; j < this.candidatos.length; j++) {
                if (reuniones[i].Candidato == this.candidatos[j].id) {
                  this.candidatos[j].reunion.push(reuniones[i]);
                }
              }
            }
            this.reuniones = reuniones;
          },
          (error) => {
            alert(error.error.errors);
          }
        );
      } else {
        console.log("ERROR");
      }
    }
  }
  procesaCerrarConfirmar(data) {
    if (!(data instanceof Event)) {
      this.activoConfirm = false;
      if (data) {
        switch (this.accion) {
          case 0:
            this.seleccionService
              .eliminarCandidato(this.activo.id, this.candidato)
              .subscribe(
                (data) => {
                  for (var j = 0; j < this.activo.candidatos.length; j++) {
                    if (this.activo.candidatos[j].id == this.candidato) {
                      this.activo.candidatos.splice(j, 1);
                      break;
                    }
                    this.notification.setNotificationBS$(true);
                  }
                },
                (error) => {
                  console.error(error);
                }
              );
            break;
          case 1:
            this.seleccionService
              .contratar(this.activo.id, this.candidato)
              .subscribe(
                (data) => {
                  this.seleccionService.getProcesos().subscribe(
                    (data) => {
                      this.procesos = <Seleccion[]>data;

                      for (var i = 0; i < this.procesos.length; i++) {
                        if (this.procesos[i].id == this.activo.id) {
                          this.activo = this.procesos[i];
                          break;
                        }
                      }
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
                },
                (error) => {
                  console.error(error);
                }
              );

            alert("Contratar");
            break;
        }
      }
    }
  }

  loadPage($event) {
    this.paginationFilters.nPage = $event;
    this.getCandidatosByProceso(this.activo.id);
  }

  // Api calls

  getCandidatosByProceso(proceso: number) {
    if (this.tipo === 1) return;
    this.seleccionService
      .getCandidatosByProceso(proceso, this.paginationFilters)
      .subscribe(
        (data) => {
          this.totalCandidatos = data.total;
          this.candidatos = data.data;
        },
        (error) => {
          alert(error.error.errors);
        }
      );
  }
}
