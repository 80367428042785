import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notificationBS$ = new BehaviorSubject<boolean >(false);

constructor() { }


getNotificationBS$() {
  return this._notificationBS$;
}

setNotificationBS$(value: boolean) {
  this._notificationBS$.next(value);
}



}
