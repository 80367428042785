import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Registro } from "../modelos/registro.model";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-autenticar",
  templateUrl: "./autenticar.component.html",
  styleUrls: ["./autenticar.component.css"],
})
export class AutenticarComponent implements OnInit {
  anio: number = new Date().getFullYear();
  recordar = false;
  formularioRegistro = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", Validators.required),
  });

  formularioOlvidar = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.email]),
  });
  usuarioParametros = new Registro();
  idioma = 1;
  bienvenida = true;
  onSubmit() {
    this.userService.autenticarUsuario(this.usuarioParametros).subscribe({
      next: (data) => {this.respuesta(data)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: (error) => this.error(error), //this.estado = error
    });
  }
  onSubmitOlvidar() {
    this.userService.lostUsuario(this.usuarioParametros).subscribe({
      next: (data) => {
        this.ventana = true;
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: (error) => {
        this.ventana1 = true;
      }, //this.estado = error
    });
  }
  ventana = false;
  ventana1 = false;
  ventana2 = false;
  error(error) {
    this.ventana2 = true;
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      alert("Correcto");
    }
    this.userService.addToken(data.token);
    this.router.navigate(["perfil"]);
  }
  constructor(protected userService: UsersService, private router: Router, private notification:NotificationService) {}

  ngOnInit() {}
}
