import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UsersService } from "../users.service";
import {
  faArrowLeft,
  faBriefcase,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { Seleccion } from "../modelos/seleccion.model";
import { SeleccionService } from "../seleccion.service";
import { environment } from "../../environments/environment";
import { ExperienciaService } from "../experiencia.service";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  faTwitter = faTwitter;
  faBriefcase = faBriefcase;
  faLinkedinIn = faLinkedinIn;
  faInstagram = faInstagram;
  faFacebookF = faFacebookF;
  faGraduationCap = faGraduationCap;
  urlServer = environment.url;
  faArrowLeft = faArrowLeft;
  procesos: Seleccion[];
  activoPopUp: boolean = false;
  constructor(
    protected userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    protected seleccionService: SeleccionService
  ) {}
  usuario: any = {
    Alumno: null,
    roles: [],
    Ciudad: null,
    Ciudad2: null,
    Descripcion: null,
    Edad: null,
    Estado: null,
    Facebook: null,
    Linkedin: null,
    NomApe: null,
    Puntos: null,
    Tel: null,
    Trabajador: null,
    Twitter: null,
    Validado: null,
    id: null,
    atribuciones: [],
    cursos: [],
    email: null,
    experiencias: [],
    formacion: [],
    herraminetas: [],
    idiomas: [],
    publicaciones: [],
    username: null,
    voluntariados: [],
    comentarios: [],
    director: [],
  };

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      let id: number = +params.get("id");
      this.userService.getUser(id).subscribe((data) => {
        this.usuario = data;
      });
    });

    this.seleccionService.getProcesosLista().subscribe(
      (data) => {
        this.procesos = <Seleccion[]>data;
      },
      (error) => {
        console.error(error);
      }
    );
  }
  proceso() {
    this.activoPopUp = true;
  }
  procesaCerrarPopUp(mensaje) {
    this.activoPopUp = mensaje;
  }
}
