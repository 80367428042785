import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { Registro } from "../modelos/registro.model";
import { CualidadesService } from "../cualidades.service";
import { Cualidades } from "../modelos/cualidades.model";
import { Seleccion } from "../modelos/seleccion.model";
import { SeleccionService } from "../seleccion.service";

@Component({
  selector: "app-buscar-candidato",
  templateUrl: "./buscar-candidato.component.html",
  styleUrls: ["./buscar-candidato.component.css"]
})
export class BuscarCandidatoComponent implements OnInit {
  candidatosTotal: Registro[];
  provincia = "";
  ciudad = "";
  nombre = "";
  fecha = "";
  candidatosPagina: Registro[];
  totalItems: number;
  page: number;
  previousPage: number;
  showPagination: boolean;
  pageSize: number = 5;
  
  cursos: Cualidades[];
  idiomas: Cualidades[];
  procesos: Seleccion[];
  titulacion: Cualidades[];
  user: number;
  activoPopUp: boolean;
  cantidadCandidatos: number;
  buscar() {
    var busqueda = {};
    busqueda["provincia"] = this.provincia;
    busqueda["ciudad"] = this.ciudad;
    busqueda["fecha"] = this.fecha;
    busqueda["nombre"] = this.nombre;
    
    
    busqueda["cursos"] = new Array();
busqueda["idiomas"] = new Array();
busqueda["formacion"] = new Array();
    for (var i = 0; i < this.cursos.length; i++) {
      if (this.cursos[i]["seleccionado"]) {
        busqueda["cursos"].push(this.cursos[i].id);
      }
    }
    for (var i = 0; i < this.idiomas.length; i++) {
      if (this.idiomas[i]["seleccionado"]) {
        busqueda["idiomas"].push(this.idiomas[i].id);
      }
    }
    for (var i = 0; i < this.titulacion.length; i++) {
      if (this.titulacion[i]["seleccionado"]) {
        busqueda["formacion"].push(this.titulacion[i].id);
      }
    }
    busqueda["pagina"] = this.page;
    busqueda["limite"] = this.pageSize;
     this.userService.findUsers(JSON.stringify(busqueda)).subscribe(data => {
      let resultado:any = data;
       this.candidatosPagina = <Registro[]>resultado.usuarios;
       this.totalItems = resultado.cantSize;
       if(this.pageSize>= this.totalItems){
        this.showPagination = false;
        }else{
          this.showPagination = true;
        }
        },
        error=> {
          if(error.status == 404){
          this.candidatosPagina = new Array();
          this.cambio(this.cantidadCandidatos);
        }
        });

  }

 cambio(size: number){
   if(size>= this.totalItems){
     this.showPagination = false;
   }else{
     this.showPagination = true;
   }
    this.pageSize = size;
    this.fillStudents(this.page);
  }

   fillStudents(page : number) : void {
     
    var busqueda = {};
    busqueda["provincia"] = this.provincia;
    busqueda["ciudad"] = this.ciudad;
    busqueda["fecha"] = this.fecha;
    busqueda["nombre"] = this.nombre;
    
    busqueda["cursos"] = new Array();
busqueda["idiomas"] = new Array();
busqueda["formacion"] = new Array();
    for (var i = 0; i < this.cursos.length; i++) {
      if (this.cursos[i]["seleccionado"]) {
        busqueda["cursos"].push(this.cursos[i].id);
      }
    }
    for (var i = 0; i < this.idiomas.length; i++) {
      if (this.idiomas[i]["seleccionado"]) {
        busqueda["idiomas"].push(this.idiomas[i].id);
      }
    }
    for (var i = 0; i < this.titulacion.length; i++) {
      if (this.titulacion[i]["seleccionado"]) {
        busqueda["formacion"].push(this.titulacion[i].id);
      }
    }
    busqueda["pagina"] = page;
    busqueda["limite"] = this.pageSize;
     this.userService.findUsers(JSON.stringify(busqueda)).subscribe(data => {
      let resultado:any = data;
       this.candidatosPagina = <Registro[]>resultado.usuarios;
       this.totalItems = resultado.cantSize;
        },
        error=> {
          if(error.status == 404){
          this.candidatosPagina = new Array();
          this.cambio(this.cantidadCandidatos);
        }
        });
  }
  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.fillStudents(this.page);
    }
  }
  
  constructor(
    protected userService: UsersService,
    protected cualidadesService: CualidadesService,
    protected seleccionService: SeleccionService
  ) {}
  usuarios: Registro[];
  tipo;
  ngOnInit() {
    
	this.page =1;
	this.previousPage =1;
    this.cantidadCandidatos = 5;
    
    var busqueda = {};
    busqueda["pagina"] = this.page;
    busqueda["limite"] = this.pageSize;
   this.userService.findUsers(JSON.stringify(busqueda)).subscribe(data => {
     let resultado:any = data;
      this.candidatosPagina = <Registro[]>resultado.usuarios;
      this.totalItems = resultado.cantSize;

      
   if(this.pageSize>= this.totalItems){
    this.showPagination = false;
  }else{
    this.showPagination = true;
  }
        });


    this.cualidadesService.getCursos().subscribe(
      data => {
        this.cursos = <Cualidades[]>data;
      },
      error => {
        console.error(error);
      }
    );
    this.cualidadesService.getIdiomas().subscribe(
      data => {
        this.idiomas = <Cualidades[]>data;
      },
      error => {
        console.error(error);
      }
    );
    this.cualidadesService.getTitulacion().subscribe(
      data => {
        this.titulacion = <Cualidades[]>data;
      },
      error => {
        console.error(error);
      }
    );


    this.seleccionService.getProcesosLista().subscribe(
        data => {
          this.procesos = <Seleccion[]>data;        
        },
        error => {
          console.error(error);
        }
      );


  }
  addAProceso(data){
    if(!(data instanceof Event)){
      this.user = data;
    this.activoPopUp = true;
    }
  }
  procesaCerrarPopUp(mensaje) {
    this.activoPopUp = mensaje;
  }
}
