import { Experiencia } from './experiencia.model';
import { Cualidades } from './cualidades.model';
import { Comentario } from './comentario.model';
import { Voluntariado } from './voluntariado.model';
import { Publicacion } from './publicacion.model';
import { Atribucion } from './atribucion.model';
import { Centro } from './centro.model';
import { Reunion } from './reunion.model';

export class Registro {
    public NomApe: string;
    public username: string;
    public Tel: string;
    public Ciudad: string;
    public Ciudad2: string;
    public Facebook: string;
    public Twitter: string;
    public Linkedin: string;
    public password: string;
    public Alumno: boolean;
    public Edad: string;
    public Descripcion: string;
    public id: number;
    public experiencias: Experiencia[];
    public idiomas: Cualidades[];
    public cursos: Cualidades[];
    public herraminetas: Cualidades[];
    public titulaciones: Cualidades[];
    public voluntariados: Voluntariado[];
    public publicaciones: Publicacion[];
    public atribuciones: Atribucion[];
    public comentarios: Comentario[];
    public centros: Centro[];
    public reunion: Reunion;
    public formacion: any[];
    public roles: any;
    public success: number;
    public director: any;
  constructor() {  }

}