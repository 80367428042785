import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { faGraduationCap, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";
import { Registro } from "../modelos/registro.model";
import { Experiencia } from "../modelos/experiencia.model";
import { Atributos } from "../modelos/atributos.model";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { ExperienciaService } from "../experiencia.service";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF
} from "@fortawesome/free-brands-svg-icons";

import { environment } from '../../environments/environment';
import { UserInfoManagerService } from "../userInfoManager.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationService } from "../notification.service";
@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.css"]
})
export class PerfilComponent implements OnInit {
faTwitter = faTwitter;
faLinkedinIn = faLinkedinIn;
faBriefcase = faBriefcase;
faInstagram = faInstagram;
faFacebookF = faFacebookF;
activoConfirm:boolean = false;
  urlServer = environment.url;
  faGraduationCap = faGraduationCap;
  tipoUser = "";
  tipoUserInt = "";
  superUser = false;
  pedagogoUser = false;
  directorUser = false;
  normalUser = false;
  constructor(
    protected userService: UsersService,
    private userInfoManagerSE:UserInfoManagerService,
    private router: Router,
  private notification:NotificationService) {}
  usuario = new Registro();
  ngOnInit() {
    this.userInfoManagerSE.initUserInfoManagerService();
    if (this.userService.isLogin()) {
      this.userService.getMe().subscribe(
        data => {
          // Success
          this.usuario = <Registro>data;
          for (var i = 0; i < this.usuario.roles.length; i++) {
            if (this.usuario.roles[i] == "ROLE_ADMIN") {
              this.superUser = true;
              this.directorUser = false;
              break;
            }
            if (this.usuario.roles[i] == "ROLE_DIRECTOR"){
              this.directorUser = true;
            }
            if(this.usuario.roles[i] == "ROLE_PEDAGOGO" ) {
              this.pedagogoUser = true;
            }
          }
          if (!this.superUser && !this.directorUser && !this.pedagogoUser) {
            this.normalUser = true;
          }
          if (this.superUser) {
            this.tipoUser = "Super Usuario";
            this.tipoUserInt = "3";
            localStorage.setItem("tipo", "3");
          } else {
            if (this.directorUser) {
              this.tipoUser = "Director";
              this.tipoUserInt = "2";
              localStorage.setItem("tipo", "2");
            } else {
               if (this.pedagogoUser) {
              this.tipoUser = "Pedagogo";
              this.tipoUserInt = "4";
              localStorage.setItem("tipo", "4");
               }else{
              this.tipoUser = "Candidato";
              this.tipoUserInt = "1";
              localStorage.setItem("tipo", "1");
               }
            }
          }
          this.userInfoManagerSE.setInfoUser(this.usuario);
          // localStorage.setItem("user", JSON.stringify(this.usuario));
        },
        error => {
          console.error(error);
          this.router.navigate([""]);
        }
      );
    } else {
      this.router.navigate([""]);
    }
  }
  cambioEstado(elemento) {
    this.userService.estadoUsuario().subscribe(
      data => {
        if (data.success == -1) {
          elemento.target.checked = false;
        } else {
          elemento.target.checked = true;
        }
        this.notification.setNotificationBS$(true);
      },
      error => {
        console.error(error);
      }
    );
  }

  onConfirmation(confirmation){
    this.activoConfirm = false;
    
    if(confirmation ){
      this.userService.removeMe().subscribe(
        data => {
          // Success
          this.userService.logout();
          this.router.navigate([""]);
          this.notification.setNotificationBS$(true);

        },
        error => {
          console.error(error);
          this.router.navigate([""]);
        }
      )
    }
  }
}
