import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Registro } from '../modelos/registro.model';

@Component({
  selector: 'app-procesos-publicos',
  templateUrl: './procesos-publicos.component.html',
  styleUrls: ['./procesos-publicos.component.css']
})
export class ProcesosPublicosComponent implements OnInit {
  urlServer = environment.url;
  usuario = new Registro();
  constructor() {}

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
  }

}