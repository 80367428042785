import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Centro } from "../modelos/centro.model";
import { CentroService } from "../centro.service";
import { FormGroup, FormControl } from "@angular/forms";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-pop-up-centros",
  templateUrl: "./pop-up-centros.component.html",
  styleUrls: ["./pop-up-centros.component.css"]
})
export class PopUpCentrosComponent implements OnInit {
  formularioBusqueda;
  faArrowLeft = faArrowLeft;
  infantil: boolean = false;
  primaria: boolean = false;
  eso: boolean = false;
  bachiller: boolean = false;
  grado: boolean = false;
  universidad: boolean = false;
  fundacion: boolean = false;
  asociacion: boolean = false;
  comunidad: string;
  @Output() propagar = new EventEmitter<boolean>();
  @Input("centros") centros: Centro[];
  constructor(protected centroService: CentroService, private notification:NotificationService) {}

  ngOnInit() {
    this.formularioBusqueda = new FormGroup({
      comunidad: new FormControl(""),
      infantil: new FormControl(""),
      primaria: new FormControl(""),
      eso: new FormControl(""),
      bachiller: new FormControl(""),
      grado: new FormControl(""),
      universidad: new FormControl(""),
      fundacion: new FormControl(""),
      asociacion: new FormControl("")
    });
    this.formularioBusqueda.valueChanges.subscribe(data => {
      this.buscar(data);
    });
  }
  buscar(info) {
    if (
      !(
        info.infantil ||
        info.primaria ||
        info.eso ||
        info.bachiller ||
        info.grado ||
        info.universidad ||
        info.fundacion ||
        info.asociacion
      )
    ) {
      for (var i = 0; i < this.centros.length; i++) {
        if (info.comunidad == this.centros[i].Comunidad) {
          this.centros[i].activo = true;
        }else{
          this.centros[i].activo = false;
        }
      }
    } else {
      for (var i = 0; i < this.centros.length; i++) {
        this.centros[i].activo = false;
        if (info.comunidad == this.centros[i].Comunidad) {
          if (info.infantil) {
            if (this.centros[i].Infantil) {
              this.centros[i].activo = true;
            }
          }
          if (info.primaria) {
            if (this.centros[i].Primaria) {
              this.centros[i].activo = true;
            }
          }
          if (info.eso) {
            if (this.centros[i].ESO) {
              this.centros[i].activo = true;
            }
          }
          if (info.bachiller) {
            if (this.centros[i].Bachillerato) {
              this.centros[i].activo = true;
            }
          }
          if (info.grado) {
            if (this.centros[i].Ciclos) {
              this.centros[i].activo = true;
            }
          }
          if (info.universidad) {
            if (this.centros[i].Universidad) {
              this.centros[i].activo = true;
            }
          }
          if (info.fundacion) {
            if (this.centros[i].Fundacion) {
              this.centros[i].activo = true;
            }
          }
          if (info.asociacion) {
            if (this.centros[i].Asociacion) {
              this.centros[i].activo = true;
            }
          }
        }
      }
    }
  }
  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }

  procesaCentro(accion) {
    if (!(accion instanceof Event)) {
      if (accion.accion) {
        this.centroService.addCentroUsuario(accion.centro).subscribe(
          data => {
            this.correcto(data, true);
            this.notification.setNotificationBS$(true);

          },
          error => {
            console.error(error);
            alert("Error: " + error.error.errors);

          }
        );
      } else {
        this.centroService.removeCentroUsuario(accion.centro).subscribe(
          data => {
            this.correcto(data, false);
            this.notification.setNotificationBS$(true);

          },
          error => {
            console.error(error);
            alert("Error: " + error.error.errors);

          }
        );
      }
    }
  }
  correcto(data, anyadido) {
    if (anyadido) {
      for (var i = 0; i < this.centros.length; i++) {
        if (this.centros[i].id == data.success) {
          this.centros[i].Seleccionado = true;
          break;
        }
      }
    } else {
      for (var i = 0; i < this.centros.length; i++) {
        if (this.centros[i].id == data.success) {
          this.centros[i].Seleccionado = false;
          break;
        }
      }

      var usuario = JSON.parse(localStorage.getItem("user"));
      for (var j = 0; j < usuario.centros.length; j++) {
        if (usuario.centros[j].id == data.success) {
          usuario.centros.splice(j, 1);
          localStorage.setItem("user", JSON.stringify(usuario));
          break;
        }
      }
    }
  }
}
