import { Component, OnInit } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Registro } from "../modelos/registro.model";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { NotificationService } from "../notification.service";

@Component({
  selector: "app-registro",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.css"]
})
export class RegistroComponent implements OnInit {
  anio: number = new Date().getFullYear();
  faArrowLeft = faArrowLeft;

  formularioRegistro = new FormGroup({
    nombre: new FormControl("", Validators.required),
    username: new FormControl("", [Validators.required, Validators.email]),
    tel: new FormControl("", Validators.required),
    ciudad: new FormControl("", Validators.required),
    ciudad2: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    pass2: new FormControl("", Validators.required)
  }, { validators: this.passwordMatchValidator });

  constructor(protected userService: UsersService, private router: Router, private notification:NotificationService) {}
  usuarioParametros = new Registro();
  ngOnInit() {} 
  
  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password')?.value;
    const pass2 = form.get('pass2')?.value;
    return password === pass2 ? null : { passwordMismatch: true };
  }
  onSubmit() {
    this.userService.addUsuario(this.usuarioParametros).subscribe({
      next: data => {this.respuesta(data)
        this.notification.setNotificationBS$(true);

      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  error(error) {
    if (error.status == "409") {
      alert("mail repetido");
    } else {
      alert("Error: " + error.error.errors);
    }
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      this.ventana = true;
    } else {
    }
  }
  ventana = false;
  cerrarVentana(){
    this.router.navigate([""]);
  }
}
