export class CualidadesUsuario {
  public id: number;
  public Lugar: string;
  public Inicio: string;
  public Final: string;
  public Cualidad: string;
  public idCualidad: string;
  public Nivel: any;

  constructor() {  }

}