import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { Reunion } from "../app/modelos/reunion.model";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReunionService {
  private url = environment.url;
  constructor(protected http: HttpClient) {
    if (localStorage.getItem("token") !== null) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token",
    }),
  };
  ponerToken() {
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }

  aceptarReunion(reunion: number): Observable<any> {
    this.ponerToken();
    return this.http.put(
      this.url + "/api/reunion/aceptar/" + reunion,
      "",
      this.httpOptions
    );
  }
  rechazarReunion(reunion: number): Observable<any> {
    this.ponerToken();
    return this.http.put(
      this.url + "/api/reunion/rechazar/" + reunion,
      "",
      this.httpOptions
    );
  }
  getReuniones(proceso: number): Observable<Reunion[]> {
    this.ponerToken();
    return this.http.get<any[]>(
      this.url + "/api/reunion/proceso/" + proceso,
      this.httpOptions
    );
  }
  postReunion(reunion: Reunion): Observable<Reunion> {
    this.ponerToken();
    return this.http.post<Reunion>(
      this.url + "/api/reunion",
      reunion,
      this.httpOptions
    );
  }
}
