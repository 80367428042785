export class Atributos {
    public centro: string;
    public inicio: string;
    public fin: string;
    public descripcion: string;
    public id: number;
    public fecha:string;
    public autor:string;
    public titulo:string;
    public revista:string;
    public url:string;
    public volumen:string;
    public puesto: string;
  constructor() {  }

}