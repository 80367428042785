import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UsersService } from "../users.service";
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';
import { Registro } from '../modelos/registro.model';
import { UserInfoManagerService } from '../userInfoManager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  urlServer = environment.url;
  usuario = new Registro();
  showMenu:boolean = false;
  constructor(protected userService: UsersService, private router: Router, private userInfoManagerSE:UserInfoManagerService) {}
tipo;
  @Input("tipo1")
  set tipo1(val:string){
    this.tipo = val;
  }
  ngOnInit() {
    this.subscribeToUserInfo();
    this.tipo = localStorage.getItem("tipo");
    // this.usuario = JSON.parse(localStorage.getItem("user"));
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.mobile = false;
    } else {
      this.mobile = true;
    }
  }
  mostrarMenu(){
    this.showMenu = !this.showMenu;
  }
  cerrarSesion(event: Event) {
    this.userService.logout();
    this.router.navigate([""]);
  }

  subscribeToUserInfo(){
    const subject = new Subject()
    this.userInfoManagerSE.getInfoUser().pipe(takeUntil(subject)).subscribe((data:Registro)=>{
      this.usuario = data;
      subject.next(null);
      subject.complete();
    })
  }

  innerWidth;
  mobile;
  
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.mobile = false;
    } else {
      this.mobile = true;
    }
  }


}